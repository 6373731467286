import request from '../../request'
import qs from 'qs';

// 课程资源

// 查询
const EduCourseResourceList = (data) => {
    return request.post(`eduCourseResource/list`, data).then(res => res.data);
}
// 删除
const EduCourseResourceRemove = (data) => {
    return request.post(`eduCourseResource/remove`, data).then(res => res.data);
}
// 上传资源
const EduCourseResourceAdd = (data) => {
    return request.post(`eduCourseResource/add`, data).then(res => res.data);
}
// 上传课程
const EduCourseResourceAdd2 = (data) => {
    return request.post(`eduCourseResource/add2`, data).then(res => res.data);
}

// 课程简介
const EduCourseList = (data) => {
    return request.post(`eduCourse/list`, data).then(res => res.data);
}
//上传资源
const UpdateEduCourse = (data) => {
    return request.post(`eduCourse/updateEduCourse`, data).then(res => res.data);
}
export {
    EduCourseResourceList,
    EduCourseResourceRemove,
    EduCourseResourceAdd,
    EduCourseResourceAdd2,
    EduCourseList,
    UpdateEduCourse
}