
<style scope lang="less">
.t_resources_cloud_dialog {
  .folder {
    width: 100%;
    height: 60px;
  }
  .fileUrl {
    width: 80px;
    height: 60px;
    background: #f5f7fa;
    border-radius: 4px;
  }
  .image-none {
    border-radius: 4px;
    width: 75px;
    height: 50px;
    margin: 6px auto 0;
    display: block;
  }
  .nav {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    li {
      color: @mainColor;
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
    }
    .navli {
      margin-left: 5px;
    }
    .lastli {
      cursor: text;
      color: #888;
    }
  }
}
</style>
<template>
  <div class="t_resources_cloud_dialog">
    <el-dialog :visible.sync="dialog" width="40%" title="我的网盘">
      <el-form size="medium" inline>
        <el-form-item label="标题">
          <el-input v-model="info" clearable placeholder="标题"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
      </el-form>
      <ul class="nav">
        <li @click="root">根目录</li>
        <li
          v-for="(n, i) in nav"
          :key="i"
          class="navli"
          :class="i === nav.length - 1 ? 'lastli' : ''"
          @click="nodes(n, i)"
        >
          > {{ n.fileName }}
        </li>
      </ul>
      <el-table
        :data="tableData"
        height="400"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        @row-click="rowClick"
      >
        <el-table-column type="selection" align="center" :selectable="selectable"></el-table-column>
        <el-table-column prop="fileUrl" width="140" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.fileType === 0" class="folder">
              <svg-icon name="folder"></svg-icon>
            </div>
            <el-image v-else :src="scope.row.fileUrl" class="fileUrl">
              <div slot="error" class="image-none">
                <img
                  class="image-none"
                  src="../../../../../assets/logo_top.png"
                />
                <!-- <i class="el-icon-picture-outline"></i> -->
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="文件名称"
          prop="fileName"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        v-if="!nav.length"
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { EduNetdiskList } from "@/libs/api/teacher/cloud";
export default {
  name: "t_resources_cloud_dialog",
  data() {
    return {
      dialog: false,
      loading: false,
      all: [],
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 10,
      info: "",
      tableChecked: [],

      nav: [],
      pid: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.query();
      }
    },
  },
  methods: {
    selectable(row){
      return row.fileType === 1;
    },
    handleSelectionChange(val) {
      this.tableChecked = val;
    },
    // 点击导航根节点
    root() {
      this.tableData = JSON.parse(JSON.stringify(this.all));
      this.nav = [];
    },
    // 点击导航子节点
    nodes(item, index) {
      // 最后一级为当前目录 不可点击
      if (index === this.nav.length - 1) {
        return;
      }
      this.pid = item.id;
      this.nav = [];
      this.tableData = this.findNodes(this.all, item.id);
    },
    // 递归查找目录
    findNodes(arr, id) {
      const list = JSON.parse(JSON.stringify(arr));
      const len = list.length;
      for (let i = 0; i < len; i++) {
        const item = list[i];
        this.nav.push(item);
        if (item.id === id) {
          return item.eduNetdiskVos;
        }
        this.findNodes(item.eduNetdiskVos, id);
      }
    },
    rowClick(row) {
      if (!row.eduNetdiskVos.length) {
        if (row.fileType === 0) {
          this.$message.error("文件夹为空");
        }
        return;
      }
      this.pid = row.id;
      this.nav.push({
        fileName: row.fileName,
        id: row.id,
      });
      this.tableData = row.eduNetdiskVos;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.query();
    },
    query() {
      let formData = new FormData();
      formData.append("page", this.page);
      formData.append("pageSize", this.pid ? '' : this.pageSize);
      formData.append("info", this.info);
      formData.append("PFileId", this.pid);
      // formData.append("teacherId", this.user.id);
      this.loading = true;
      EduNetdiskList(formData)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.all = res.data.list;
          if (!this.pid) {
            this.nav = [];
          }
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    confirm() {
      if (!this.tableChecked.length) {
        return this.$message.error("请至少选择一个文件或文件夹");
      }
      this.$emit("changeTableChecked", this.tableChecked);
    },
  },
};
</script>
