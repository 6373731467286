
<style scoped lang="less">
.Cloud_ViewModel {
  .items {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border: 2px solid #ececec;
    border-radius: 8px;
    width: 160px;
    height: 160px;
    padding: 20px 0 0px;
    &:hover {
      position: relative;
      .bottom {
        display: inline-block;
        position: absolute;
        height: 35px;
        line-height: 35px;
        bottom: 0;
      }
    }
    .el-checkbox {
      margin-left: 10px;
    }
    .folder {
      margin-top: 10px;
      width: 100%;
      height: 130px;
    }
    .fileUrl {
      cursor: pointer;
      margin: 10px 0;
      width: 180px;
      height: 110px;
      background: #f5f7fa;
      border-radius: 4px;
    }
    .image-none {
      border-radius: 4px;
      width: 110px;
      height: 80px;
      margin: 15px auto;
      display: block;
    }
    .bottom {
      display: none;
      width: 100%;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      .el-col {
        cursor: pointer;
      }
    }
  }
  .pdb40 {
    &:hover {
      padding: 10px 0 40px;
    }
  }
  .fileName {
    text-align: center;
    box-sizing: border-box;
    width: 180px;
    height: 30px;
    line-height: 30px;
    margin: 8px 0;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
<template>
  <div class="Cloud_ViewModel">
    <slot name="head"></slot>
    <el-row :gutter="30">
      <el-col
        v-for="(item, index) in data"
        :key="index"
        :xs="24"
        :sm="12"
        :md="8"
        :lg="4"
      >
        <div class="items">
          <!-- <el-checkbox v-model="item.check"></el-checkbox> -->
          <!-- isBtn ? 点击图片 ： undifnied -->
          <div v-if="item.fileType === 0" class="folder">
            <svg-icon name="folder"></svg-icon>
          </div>
          <el-image v-else :src="item.fileUrl" class="fileUrl">
            <div slot="error" class="image-none">
              <img
                class="image-none"
                src="../../../../../assets/logo_top.png"
              />
            </div>
          </el-image>
          <el-row class="bottom" v-if="item.fileType !== 0">
            <el-col :span="8" @click.native="handleDownload(item)">下载</el-col>
            <el-col :span="8" @click.native="handleView(item)">预览</el-col>
            <el-col :span="8" @click.native="handleDelete(item)">删除</el-col>
          </el-row>
        </div>
        <el-tooltip
          class="fileName"
          effect="dark"
          :content="item.resourceName"
          placement="top"
        >
          <span>{{ item.resourceName }}</span>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :visible.sync="dialog"
      width="40%"
      title="预览"
      @close="closeAudio()"
    >
      正在播放.......
    </el-dialog>
    <el-dialog
      :visible.sync="dialog2"
      width="40%"
      title="预览"
      @close="closeAudio2()"
    >
      <div>
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        >
        </video-player>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialog3" width="40%" title="预览">
      <el-image :src="imagesUrl" style="width: 100%; height: 100%"></el-image>
    </el-dialog>
    <el-dialog :visible.sync="dialog4" width="40%" title="预览">
      <div v-html="resHtml"></div>
    </el-dialog>
  </div>
</template>

<script>
const audio = new Audio();
import { EduCourseResourceRemove } from "@/libs/api/teacher/resources";
export default {
  name: "cloud_viewModel",
  props: {
    data: Array,
    total: Number,
    page: Number,
    pageSize: Number,
  },
  data() {
    return {
      resHtml: "",
      dialog4: false,
      imagesUrl: "",
      dialog3: false,
      dialog2: false,
      dialog: false,
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: "",
          },
        ],
        //你的封面地址
        poster: "",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
      },
    };
  },
  methods: {
    handleView(row) {
      //文档
      if (row.resourceType == 1) {
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" + row.resourceUrl
        );
      }
      //音频
      if (row.resourceType == 2) {
        this.dialog = true;
        audio.src = row.resourceUrl;
        let playPromise;
        playPromise = audio.play();
      }
      //视频
      if (row.resourceType == 3) {
        this.dialog2 = true;
        this.playerOptions.sources[0].src = row.resourceUrl;
      }
      //图片
      if (row.resourceType == 4) {
        this.dialog3 = true;
        this.imagesUrl = row.resourceUrl;
      }
      if (row.resourceType == 5) {
        this.dialog4 = true;
        this.resHtml = row.resourceUrl;
      }

      if (row.resourceType == 6) {
        this.$message.warning("无法预览");
      }
    },
    // 下载
    handleDownload(row) {
      if (row) {
        if (!row.resourceUrl) {
          return this.$message.error("无下载地址");
        }
        window.open(row.resourceUrl);
      }
    },
    // 删除
    handleDelete(row) {
      if (row) {
        this.$confirm("确定要删除吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let data = new FormData();
            data.append("ids", row.id);
            EduCourseResourceRemove(data).then((res) => {
              this.$message.success("删除成功");
              this.$emit("initQuery");
            });
          })
          .catch(() => {});
      }
    },
    closeAudio2() {
      this.$refs["videoPlayer"].player.pause();
    },
    closeAudio() {
      audio.pause();
    },
    handleRootNav() {},
    handleCurrentChange(page) {
      if (page) {
        this.$emit("pageInit", page);
      }
    },
  },
};
</script>
