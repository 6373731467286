<style lang="less" scoped>
.t_course_resources {
  box-shadow: @shadow;
  background: #fff;

  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }

  .content {
    padding: 20px;

    .header {
      margin-bottom: 20px;

      .resources_input {
        width: 180px;
        margin-left: 10px;
      }

      .resources_select {
        margin: 0 10px;
        width: 200px;
      }

      .dropdown_menu {
        margin-left: 10px;
      }

      .switch_icon {
        margin-left: 10px;
        display: inline-block;
        position: relative;
        top: 2px;

        i {
          margin-right: 10px;
          cursor: pointer;
          font-size: 20px;
        }

        .active {
          color: @mainColor;
        }
      }
    }
  }
}
</style>
<template>
  <div class="t_course_resources">
    <div class="stu-module-header">
      <div class="stu-module-title">课程资源</div>
    </div>
    <div class="content">
      <div class="header">
        <el-button @click="handleCloud" type="primary">我的网盘</el-button>
        <el-button @click="handleResources" type="primary">上传资源</el-button>
        <el-input
          class="resources_input"
          v-model="bo.resourceName"
          placeholder="资源名称"
          size="medium"
          clearable
        ></el-input>
        <el-select
          class="resources_select"
          v-model="bo.resourceType"
          size="medium"
          placeholder="全部"
        >
          <el-option
            v-for="(item, index) in resourcesOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button @click="initQuery(null)" type="primary">查询</el-button>
        <el-dropdown @command="command" class="dropdown_menu">
          <span class="el-dropdown-link">
            {{ orderType | typeFilter
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">文件名称</el-dropdown-item>
            <el-dropdown-item command="2">文件大小</el-dropdown-item>
            <el-dropdown-item command="3">最近上传</el-dropdown-item>
            <el-dropdown-item command="4">修改时间</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <p class="switch_icon">
          <i
            @click="active = true"
            class="el-icon-s-operation"
            :class="active ? 'active' : ''"
          ></i>
          <i
            @click="active = false"
            class="el-icon-s-grid"
            :class="!active ? 'active' : ''"
          ></i>
        </p>
      </div>
      <div v-loading="loading">
        <!-- 列表模式 -->
        <ListModel
          v-if="active"
          :tableData="modelData"
          :columns="columns"
          :total="total"
          :page="page"
          :pageSize="pageSize"
          @pageInit="pageInit"
          @initQuery="initQuery"
        ></ListModel>
        <!-- 视图模式 -->
        <view-model
          v-else
          :data="modelData"
          :total="total"
          :page="page"
          :pageSize="pageSize"
          @initQuery="initQuery"
          @pageInit="pageInit"
        >
        </view-model>
      </div>
    </div>
    <!-- 我的网盘 -->
    <MyCloud ref="resources_my_cloud_ref" @changeTableChecked="add" />
    <!-- 上传资源 -->
    <UploadResources ref="resources_upload_file_ref" @initQuery="initQuery"/>
  </div>
</template>

<script>
import ListModel from "./cloud/ListModel.vue";
import ViewModel from "./cloud/ViewModel";
import MyCloud from "./module/Cloud.vue";
import UploadResources from "./module/UploadFile.vue";
import {
  EduCourseResourceList,
  EduCourseResourceAdd2,
} from "@/libs/api/teacher/resources";
export default {
  name: "t_course_resources",
  components: { ListModel, ViewModel, MyCloud, UploadResources },
  data() {
    return {
      loading: false,
      resourcesOptions: [
        { label: "全部", value: "" },
        { label: "文档", value: "1" },
        { label: "音视频", value: "2" },
        { label: "视频", value: "3" },
        { label: "图片", value: "4" },
        { label: "网页", value: "5" },
        { label: "压缩包", value: "6" },
      ],
      orderType: "4",
      active: true,
      modelData: [],
      columns: [
        { label: "文件名称", prop: "labelName", width: 140 },
        // { label: "标签", prop: "labelName", width: 140 },
        { label: "创建时间", prop: "createDate", width: 140 },
      ],
      total: 0,
      page: 1,
      pageSize: 10,
      bo: {
        resourceName: "", //资源名称
        resourceType: "", //资源类型
      },
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  filters: {
    typeFilter(val) {
      // 1.文件名 2文件大小 3最近上传 4.修改时间
      if (val === "1") return "文件名";
      else if (val === "2") return "文件大小";
      else if (val === "3") return "最近上传";
      else if (val === "4") return "修改时间";
    },
  },
  activated() {
    this.initQuery(null);
  },
  methods: {
    add(row) {
      let formData = new FormData();
      formData.append("courseId", this.course.id);
      formData.append("ids", row.map((m) => m.id).join());
      EduCourseResourceAdd2(formData).then((res) => {
        this.$message.success("上传成功");
        this.initQuery(null);
        this.$refs["resources_my_cloud_ref"].dialog = false;
      });
    },
    initQuery(orderType) {
      let formData = new FormData();
      formData.append("page", this.page);
      formData.append("pageSize", this.pageSize);
      formData.append("courseId", this.course.id);
      formData.append("resourceName", this.bo.resourceName);
      formData.append("resourceType", this.bo.resourceType);
      formData.append("orderType", orderType || this.orderType);
      this.loading = true;
      EduCourseResourceList(formData)
        .then((res) => {
          this.loading = false;
          this.modelData = res.data.list;
          this.modelData.splice();
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    pageInit(page) {
      this.page = page;
      this.initQuery(null);
    },
    handleCloud() {
      this.$refs["resources_my_cloud_ref"].dialog = true;
    },
    handleResources() {
      this.$refs["resources_upload_file_ref"].dialog = true;
    },
    command(name) {
      this.orderType = name;
      this.initQuery(name);
    },
  },
};
</script>
