<style scoped lang="less">
.Cloud_ListModel {
  .folder {
    width: 100%;
    height: 90px;
  }
  .coverUrl {
    width: 110px;
    height: 80px;
    background: #f5f7fa;
    border-radius: 4px;
  }

  .image-none {
    border-radius: 4px;
    width: 100px;
    height: 70px;
    margin: 6px auto;
    display: block;
  }
}
</style>
<template>
  <div class="Cloud_ListModel">
    <slot name="head"></slot>
    <el-table :data="tableData" :height="450" @row-click="rowClick">
      <el-table-column label="序号" align="center" width="70">
        <template slot-scope="scope">
          <span>{{ scope.$index + (page - 1) * pageSize + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" width="160">
        <template slot-scope="scope">
          <el-image :src="scope.row.resourceUrl" class="coverUrl">
            <div slot="error" class="image-none">
              <img
                class="image-none"
                src="../../../../../assets/logo_top.png"
              />
            </div>
          </el-image>
          <!-- <svg-icon name="folder"></svg-icon> -->
        </template>
      </el-table-column>

<!--      <el-table-column-->
<!--        v-for="(item, index) in columns"-->
<!--        :prop="item.prop"-->
<!--        :key="index"-->
<!--        :label="item.label"-->
<!--        :min-width="item.width"-->
<!--        show-overflow-tooltip-->
<!--        align="center"-->
<!--      ></el-table-column>   -->
      <el-table-column
        prop="labelName"
        label="文件名称"
        min-width="140"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createDate"
        label="创建时间"
        min-width="140"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column align="center" width="160" prop="fileType" label="类型">
        <template slot-scope="scope">
          <span>{{ scope.row.resourceType | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        width="160"
        prop="fileSize"
        label="文件大小"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.fileSize">{{
            scope.row.fileSize | numFilter
          }}</span>
          <span v-if="scope.row.resourceSize">{{
            scope.row.resourceSize | numFilter
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" :width="180">
        <template slot-scope="scope">
          <div v-if="scope.row.fileType !== 0">
            <el-button type="text" @click="handleDownLoad(scope.row)"
              >下载
            </el-button>
            <el-button type="text" @click="handleView(scope.row)"
              >预览</el-button
            >
            <el-button type="text" @click="handleDelete(scope.row)">
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <el-dialog
      :visible.sync="dialog"
      width="40%"
      title="预览"
      @close="closeAudio()"
    >
      正在播放.......
    </el-dialog>
    <el-dialog
      :visible.sync="dialog2"
      width="40%"
      title="预览"
      @close="closeAudio2()"
    >
      <div>
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        >
        </video-player>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialog3" width="40%" title="预览">
      <el-image :src="imagesUrl" style="width: 100%; height: 100%"></el-image>
    </el-dialog>
    <el-dialog :visible.sync="dialog4" width="40%" title="预览">
      <div v-html="resHtml"></div>
    </el-dialog>
  </div>
</template>

<script>
const audio = new Audio();
import { EduCourseResourceRemove } from "@/libs/api/teacher/resources";
export default {
  name: "cloud_listModel",
  props: {
    tableData: Array,
    columns: Array,
    total: Number,
    page: Number,
    pageSize: Number,
  },
  data() {
    return {
      resHtml: "",
      dialog4: false,
      imagesUrl: "",
      dialog3: false,
      dialog2: false,
      dialog: false,
      playerOptions: {
        //播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        //如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: "",
          },
        ],
        //你的封面地址
        poster: "",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
      },
    };
  },
  filters: {
    // 文件大小单位
    numFilter(value) {
      let realVal = (value / 1024).toFixed(2);
      if (realVal > 1024) {
        return (realVal / 1024).toFixed(2) + "M";
      }
      return realVal + "KB";
    },
    // 文件类型
    typeFilter(value) {
      if (!value) return "-";
      value = value + "";
      const ware = [
        "1.文档",
        "2.音频",
        "3.视频",
        "4.图片",
        "5.网页",
        "6.压缩包",
      ];
      const find = ware.find((f) => f.split(".")[0] === value);
      return find ? find.split(".")[1] : value;
    },
  },
  methods: {
    handleRootNav() {},
    handleCurrentChange(page) {
      if (page) {
        this.$emit("pageInit", page);
      }
    },
    // 点击行
    rowClick(row) {},
    handleDownLoad(row) {
      if (row) {
        if (!row.resourceUrl) {
          return this.$message.error("无下载地址");
        }
        window.open(row.resourceUrl);
      }
    },
    handleView(row) {
      //文档
      if (row.resourceType == 1) {
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" + row.resourceUrl
        );
      }
      //音频
      if (row.resourceType == 2) {
        this.dialog = true;
        audio.src = row.resourceUrl;
        let playPromise;
        playPromise = audio.play();
      }
      //视频
      if (row.resourceType == 3) {
        this.dialog2 = true;
        this.playerOptions.sources[0].src = row.resourceUrl;
      }
      //图片
      if (row.resourceType == 4) {
        this.dialog3 = true;
        this.imagesUrl = row.resourceUrl;
      }
      if (row.resourceType == 5) {
        this.dialog4 = true;
        this.resHtml = row.resourceUrl;
      }

      if (row.resourceType == 6) {
        this.$message.warning("无法预览");
      }
    },
    handleDelete(row) {
      if (row) {
        this.$confirm("确定要删除吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let data = new FormData();
            data.append("ids", row.id);
            EduCourseResourceRemove(data).then((res) => {
              this.$message.success("删除成功");
              this.$emit("initQuery");
            });
          })
          .catch(() => {});
      }
    },
    closeAudio2() {
      this.$refs["videoPlayer"].player.pause();
    },
    closeAudio() {
      audio.pause();
    },
  },
  watch:{
    tableData (newVal){
    }
  }
};
</script>
