<template>
  <el-dialog title="上传资源" :visible.sync="dialog" width="45%">
    <div class="t_resources_upload_file">
      <el-upload
          class="upload-demo"
          multiple
          ref="upload"
          action="#"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :file-list="fileList"
          :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary">
          选取文件
        </el-button>
      </el-upload>

      <div v-if="formList.length > 0">
        <el-form
            inline
            v-for="(f, i) in formList"
            :key="i"
            :model="f.form"
            :rules="f.rules"
            :ref="`uploadFile_form_ref_${i + 1}`"
            label-width="120px"
        >
          <el-form-item :label="`${i + 1}、资源名称`" prop="name">
            <el-input v-model="f.form.name"></el-input>
          </el-form-item>
          <el-form-item label="文件名称" prop="labelName">
            <el-input v-model="f.form.labelName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="f.form.percent"
            ></el-progress>
          </el-form-item>
        </el-form>
      </div>

      <div class="message" v-else>
        <p class="messsage_title">上传须知</p>
        <div class="messsage_content">
          <p>1、为确保上传成功，我们建议您每次最多上传不超过20份文档</p>
          <p>2、单个文件大于100M的文档建议使用客户端上传</p>
          <p>
            <span>3、本系统目前支持以下的系统格式文档：</span>
            <span
            >office文档： doc docx xls xlsx wps wpt dps pdf rtf txt ppt
              pptx</span
            >
            <span>音视频： mp3 mp4</span>
            <span>图片： jpg jpeg png tif tiff bmp gif</span>
            <span>压缩包： 7z rar tar zip</span>
          </p>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close"> 关闭 </el-button>
      <el-button size="small" type="primary" :loading="loading" @click="save"
      >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {EduCourseResourceAdd} from "@/libs/api/teacher/resources";
export default {
  name: "t_resources_upload_file",
  data() {
    return {
      loading: false,
      dialog: false,
      fileList: [],
      formList: [],
      pid: null,
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  methods: {
    recursivePercent(bool, index) {
      if (bool) {
        return;
      }
      const p = this.formList[index].form.percent;
      let addPercent = Math.round(Math.random() * 4) + 1;
      if (p <= 94) {
        this.formList[index].form.percent = this.formList[index].form.percent + addPercent;
      }
      if (this.formList[index].form.percent >= 100) {
        return;
      }
      setTimeout(() => {
        this.recursivePercent(false,index);
      }, 1000);
    },
    close() {
      this.dialog = false;
    },
    save() {
      if (this.fileList.length === 0) return this.$message.error("请选择文件");
      let isValid = false;
      for (let i = 0; i < this.formList.length; i++) {
        this.$refs[`uploadFile_form_ref_${i + 1}`][0].validate((valid) => {
          if (!valid) {
            isValid = true;
            return false;
          }
        });
      }
      if (!isValid) {
        this.upload(0);
      }

    },
    upload(index) {
      this.recursivePercent(false,index);
      let formData = new FormData();
      formData.append("files", this.fileList[index].raw);
      formData.append("labelName", this.formList[index].form.labelName);
      formData.append("courseId", this.course.id);
      EduCourseResourceAdd(formData)
          .then((resp) => {
            if (resp.code === 2000){
              setTimeout(() => {
                this.recursivePercent(true,index);
                this.formList[index].form.percent = 100;
                if (index === this.formList.length - 1) {
                  setTimeout(() => {
                    this.fileList = [];
                    this.formList = [];
                    this.$message.success("上传成功");
                    this.$emit("initQuery");
                  }, 600);
                  // this.dialog = false;
                } else {
                  index++;
                  this.upload(index);
                }
              },1000)
            }else {
              this.recursivePercent(true,index);
              this.formList[index].form.percent = 0;
              setTimeout(() => {
                this.fileList = [];
                this.formList = [];
                this.$message.success("上传失败");
                this.$emit("initQuery");
              }, 600);
            }
          })
          .catch((err) => {
            this.recursivePercent(true,index);
            this.formList[index].form.percent = 0;
            setTimeout(() => {
              this.fileList = [];
              this.formList = [];
              this.$message.success("上传失败");
              this.$emit("initQuery");
            }, 600);
          });
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      for (let i = 0; i < this.formList.length; i++) {
        const el = this.formList[i];
        if (el.uid === file.uid) {
          this.formList.splice(i, 1);
          break;
        }
      }
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      let uidMap = this.formList.map((m) => m.uid);
      if (!uidMap.includes(file.uid)) {
        this.formList.push({
          uid: file.uid,
          form: {
            name: file.name,
            labelName: null,
            percent: 0,
          },
          rules: {
            name: [{required: true, message: "资源名称不能为空"}],
            labelName: [{required: true, message: "文件名称不能为空"}],
          },
        });
      }
    },
    onSuccess(res) {
      this.formList = [];
      this.fileList = [];
    },
    onError(res) {
      // this.file
      this.formList = [];
      this.fileList = [];
      this.$message.error(res.message);
    },
  },
};
</script>

<style lang="less">
.t_resources_upload_file {
  display: flex;

  .upload-demo {
    width: 200px;
  }

  .el-form {
    .el-form-item {
      width: 100%;

      .el-form-item__content {
        width: calc(100% - 120px);
      }

      .el-progress {
        width: 100%;
        margin-top: 10px;
        margin-left: 120px;
      }
    }
  }

  .message {
    .messsage_title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .messsage_content {
      font-size: 14px;

      p {
        margin-bottom: 10px;

        span {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
